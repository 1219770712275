const EN = {
  en: 'English',
  de: 'Deutsch',
  it: 'Italiano',
  fr: 'Français',
  es: 'Español',
  language: 'English',
  help: 'Help',
  newHere: 'New here? ',
  createAccount: 'Create account',
  forgotPassword: 'I forgot my password',
  rememberMe: 'Remember me',
  siroBlue: 'Siroforce Mobile',
  signIn: 'LOGIN',
  faq: 'FAQ/Feedback/Support',
  mobileOverview: 'Siroforce Mobile Overview',
  siroforceMobile: 'Access to Siroforce Mobile',
  deviceRegistration: 'Device Registration',
  ticketing: 'Service Request / Ticket',
  preventiveMaintenance: 'Preventive Maint. / Market Action',
  marketAction: 'Market Action',
  monitoring: 'Evaluation and Reporting',
  helpcenter: 'Helpcenter',
  welcome: 'Welcome to Siroforce Mobile.',
  access: "Your access to Dentsply Sirona's technical support.",
  email: 'Email address',
  password: 'Password',
  rightsReserved: '© 2024 Dentsply Sirona. All rights reserved.',
  privacy: 'Privacy Policy',
  termsOfUse: 'Terms of Use',
  imprint: 'Imprint',
  commercialArea: 'Commercial area',
  compliance: 'Compliance',
  inc: 'Dentsply Sirona Inc',
  instructions: 'Instructions for Use',
  register: 'Register',
  emailFieldLabel: 'Email (Login)',
  provideValidEmail: 'Please provide a valid email address',
  provideValidPassword: 'Please provide valid password',
  passwordsDoNotMatch: 'Passwords do not match',
  fieldRequired: 'Field is required',
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone Number',
  noPartnersFound: 'No partners found.',
  zipCode: 'Zip',
  chooseState: 'Choose a state',
  requiredPartner: 'A partner has to be selected!',
  myRole: 'My role',
  technician: 'Service technician',
  sales: 'Sales',
  chooseCountry: 'Choose a country',
  passwordRepeat: 'Passwort repeat',
  emailTaken: 'E-Mail already taken',
  signUp: 'Sign up',
  error: 'Error',
  registeredInClassic:
    'You are already registered for Siroforce Mobile. Please log in',
  registeredInEKP: 'You are already registered for EKP. Please log in',
  close: 'Close',
  confirm: 'Confirm',
  none: 'None',
  registrationSuccess:
    'Thank you for your registration. If you do not receive a confirmation email, please check your spam folder. Only when your email address has been validated can the registration be completed.',
  registrationReceived: 'Registration request received',
  registrationFailed: 'Registration failed. Please try again later.',
  youAre: 'You are?',
  endCustomer: 'End Customer',
  technicianSales: 'Technician / Sales',
  thirdParty: '3rd Party',
  check: 'Send',
  alreadyRegisteredEKPStart: 'A user with your email address already exists',
  here: 'here',
  notRegisteredInEKP: 'There is no user yet. Please register.',
  alreadyExistingInTechnicianButEKP:
    'An account already exists for your email address in the technician portal.',
  wantToTransferToEKP:
    'However, if you would like to move your account to the customer complaint portal, please send an email to %s',
  alreadyRegisteredInClassic: 'A user with your email address already exists',
  notRegisteredInClassic: 'There is no user yet. Please register.',
  alreadyExistingInEKPButTechnician:
    'There is already an account for your email address in the  customer complaint portal.',
  wantToTransferToClassic:
    'However, if you would like to move your account to technician portal, please send an email to %s',
  confirmHeading: 'Thank you for confirming your e-mail address.',
  confirm1Body:
    'Dentsply Sirona dealers usually receive an e-mail within a few minutes with the activation for Siroforce Mobile. If you do not receive an e-mail in the short term, your registration must be checked by Dentsply Sirona. We will usually get back to you within 1-2 business days.',
  confirm2Body:
    'Your account has been activated. You can now log in directly to Siroforce Mobile.',
  loginSiroforce: 'Login Siroforce Mobile',
  successfullLogin: 'Successfully logged in!',
  errorLoggingIn: 'Error logging in',
  provideValidQueryParams: 'Provide valid query parameters.',
  verifyAssetInformation: 'Verify device Information',
  assetFound:
    'Please verify the following serial and reference number with the ones displayed on the product label on the backside of the device.',
  materialNumber: 'REF / Model-No.',
  serialNumber: 'SN / Serial-No.',
  pleaseConfirm: 'Please confirm',
  false: 'Not correct',
  unexpectedError: 'Unexpected error. Please try again later',
  invalidToken:
    'Error. Check whether your token is valid and not expired and try again.',
  assetNotFound: 'Could not find your equipment. Please select manually.',
  yourDevice: 'Enter Device Information',
  enterMaterialAndSerial:
    'Please enter the device information of serial and reference number displayed on the product label on the backside of the device.',
  next: 'Next',
  enterPostalCode: 'Enter Postal Code',
  authorizeByEnteringPostalCode:
    'We found an existing registration for your device with Dentsply Sirona. Please verify the existing registration by entering your postal code.',
  postalCode: 'Postal Code',
  customerNotFound: 'Could not find customer. Please select manually.',
  checkContactInformation: 'Verify Registration',
  checkRelatedMasterData:
    'Please confirm your existing registration with Dentsply Sirona or make the appropriate corrections.',
  correction: 'Not correct',
  customerData: 'Your Organization',
  enterCustomerData:
    'Your device is not yet registered with Dentsply Sirona. Please enter your clinic information to complete the registration process.',
  name: 'Clinic name',
  street: 'Street',
  city: 'City',
  houseNumber: 'Number',
  country: 'Country',
  state: 'State',
  allowAddressOptimization:
    'Would you like us to optimize your address information?',
  reject: 'Reject',
  sent: 'Sent:',
  suggested: 'Suggested:',
  selectDealer: 'Your Service Partner',
  selectDealerWhichMaintainsDevice:
    'Please select your preferred service partner for your device.',
  foundTopEquipment: 'Successfully identified your equipment.',
  credentialsWentWrong:
    'Something went wrong with your authentication. Please use your url with token to gain access.',
  submitSuccess: 'Thank you! Your registration is now complete.',
  confirmDealer: 'Verify Service Partner',
  checkRelatedDealer:
    'Please confirm your service partner information or make the appropriate corrections.',
  identifyDevice: 'Select your device',
  pleasePickDevice:
    'We need some additional information about your device. Please select the applicable product category for your device to continue with the registration.',
  select: 'Select',
  deviceHasToBeSelected: 'A device should be selected!',
  dealerUnknown: 'Dealer unknown',
  addMissingDealer: 'Add missing dealer',
  wrongPhoneFormat: 'Invalid phone format',
  wrongZipFormat: 'Invalid zip code format',
  welcomeToDentsply: 'Welcome to Dentsply Sirona!',
  welcomeImageText:
    'Together with you we drive integrated, intelligent, customer centric solutions and services to provide better treatment outcomes and patient care. Please complete your device registration in the following steps to enable us to support you in the best way forward.',
  cerecPrimeScan: 'CEREC Primescan',
  cerecOmniScan: 'CEREC Omnicam',
  clinic: 'Clinic',
  serviceCenter: 'Service Center',
  deviceSuccessfullyConnected:
    'Your device is now successfully connected to the cloud. Thanks to your valuable support, we are able to continuously improve customer experience.',
  deviceSuccessfullyConnected2:
    'You can finish the session, by closing this browser window.',
  continue: 'Continue',
  authorize: 'Authorize',
  makeCorrections:
    'Please make the appropriate corrections to your clinic information by clicking in the respective box.',
  postalCodeFail:
    'Authorization via postal code was not successful. Please enter your clinic information to complete the registration process.',
  oops: 'Oops!',
  somethingWentWrong: 'Something went wrong.',
  sessionTimedOut:
    'Your session has timed out. Click the link "Check device registration online" in the Dentsply Sirona System Monitor application to restart this process. Close this browser tab to get back to System Monitor.',
  authorizedTechnicianSales: 'Authorized dealer <br> Dentsply Sirona employee',
  egDentist: '(e.g. dentist, laboratory, clinic)',
  egITService: '(e.g. IT service provider)',
  iAm: 'I am...',
  yourEmailAddress: 'Your email address:',
  welcomeDentsplyProductService:
    'Welcome to the Dentsply Sirona Product Service',
  selectRole: 'Please select your role.',
  yes: 'Yes',
  redirectedToTechnicianPortal:
    'You will now be redirected to the technician portal...',
  registerHere: 'Click here to register',
  redirectedToCustomerPortal:
    'You will now be redirected to the customer complaint portal...',
  asThirdPartyPleaseCall:
    'As a third-party supplier, please contact us by telephone.',
  accessToTheDentsply:
    'Access to the Dentsply Sirona service portals is reserved exclusively for end customers, autorized dealers and Dentsply Sirona employees.',
  pleaseContact:
    'Please contact the responsible Dentsply Sirona dealer with your request or contact the Dentsply Sirona Product Service.',
  pleaseCallUs: 'Please call us.',
  findPhoneNumbers: 'Please click on this link to research for phone numbers: ',
  open: 'Open',
  technicianPortal: 'technician portal',
  filter: 'Filter (search for name, city or zip)',
  loginTechnicianPortal: 'Login for technician / DS employee',
  loginComplaintPortal: 'Login for customer',
  notOfficialDealerDomain:
    'Your email domain is currently not registered as an official dealer domain. An activation must be done manually and might take several days.',
  confirmErrorBody: 'Email verification failed.',
  maintenanceHeading: 'Wartungsmodus',
  maintenanceBody:
    'Es finden gerade Wartungsarbeiten für Siroforce statt. Wir sind bald wieder zurück.',
  request: 'Request',
  status: 'status',
  noteSendError: 'Could not send your Note. Please try again later',
  pleaseTypeYourMessage: 'Please type your message for us here...',
  feedbackHeader:
    'Thank you for taking the time to provide feedback. This will help us to improve this site for everybody.',
  feedBackHint:
    'This feedback form is intended to report errors or missing items/topics on this support site. The web team cannot provide technical service or forward technical requests to a service team in a far-away country. Please contact your local dealer or service team with any product questions.',
  feedBackThankYou: 'Thank you for your understanding!',
  feedbackTitle: 'Feedback',
  feedbackName: 'Name',
  feedbackSubmit: 'Submit',
  feedbackEmail: 'Email',
  feedbackDetails: 'Your Feedback',
  feedbackSubmitSuccess: 'Thank you for your feedback!',
  feedbackSubmitError: 'Could not send your feedback. Please try again later.',
  urlCopiedToClipboard: 'URL copied to clipboard',
  invalidMaterialNumber: 'Invalid material number. Please enter manually.',
  device: 'device',
  description: 'description',
  customer: 'customer',
  cancel: 'Cancel',
  createDate: 'Create date',
  dropFilesHere: 'Drop files here',
  dropFilesOrSelect: 'Drag and drop files here, or click to select files',
  fileTooLarge: 'You can only upload files up to 5MB',
  localRequestStatusAll: 'All',
  localRequestStatusClosed: 'Closed',
  localRequestStatusInProgress: 'In progress',
  localRequestStatusOpen: 'Open',
  localRequestStatusPending: 'Pending',
  messageSendError: 'Could not send your message. Please try again later.',
  only5FilesAllowed: 'You can only upload up to 5 files',
  requestStatusAdditionProvided: 'In progress',
  requestStatusCancelled: 'Cancelled',
  requestStatusCompleted: 'Completed',
  requestStatusCustomerAction: 'Waiting for your reply',
  requestStatusForwarded: 'In progress',
  requestStatusInProgress: 'In progress',
  requestStatusSolutionProvided: 'Solution Provided',
  uploadAttachments: 'Upload attachments',
  PleaseContactYourSupportTeam: 'Please contact your support team',
  ErrorCode: 'Error code: ',
  pushNotification: 'Push notification',
  repairOrder: 'Repair Order',
  INIT: 'Initiated',
  AWDE: 'Awaiting device',
  REPA: 'Repair completed',
  CANC: 'Cancelled',
  INSP: 'Inspection',
  AWCA: 'Awaiting customer approval',
  REST: 'Repair started',
  REDE: 'Repair denied',
  CLOS: 'Closed',
  REAP: 'Repair approved',
  REPE: 'Repair pending',
  DERE: 'Device received',
  approveRepair: 'Approve repair',
  approveRepairDialogText: 'You have decided on a repair.\n\nPlease confirm your decision.',
  rejectRepairAndReturnDevice: 'Reject repair & return device',
  rejectRepairAndReturnDeviceDialogText: 'You have declined the cost estimate and decided on a return of the device.\n\nPlease note that additional costs may be incurred for the return shipping.\n\nPlease confirm your decision.',
  rejectRepairAndScrapDevice: 'Reject repair & scrap device',
  rejectRepairAndScrapDeviceDialogText: 'You have declined the cost estimate and opted for the disposal of the device.\n\nPlease note that additional costs may be incurred for the disposal.\n\nPlease confirm your decision.',
  SL_USA: 'In order to prepare your device for shipment please follow the instructions below:\n\n-Pack the device in a suitable box\n-Make two copies of the attached shipping label\n-Place attach one version firmly to the outside of the box; the other copy needs to be put inside the box.\n-Please take the box to the nearest UPS drop-off location\n\nYours sincerely,\nDentsply Sirona Repair Center',
  DR_USA: 'We have received your device for repair. Enclosed you will find the evaluation report. \n\nYours sincerely,\nDentsply Sirona Repair Center',
  KV_USA: 'We have received your device for repair. Enclosed you will find an estimate for approval.\nTo approve, simply click on the button below.\n\nWe are available to answer any questions you may have. You are welcome to use the messaging function. \n\nYours sincerely,\nDentsply Sirona Repair Center',
  RR_USA: 'We have completed the repair of your device. Attached please find the details of your repair.\n\nWe are available to answer any questions you may have. You are welcome to use the messaging function.\n\nYours sincerely,\nDentsply Sirona Repair Center',
  SL: 'Dear customer,\n\nPlease find enclosed the send-in label for the shipment of your device. Please pack the device and enclose the send-in label in the package. The shipping label will be provided by the freight service provider on the day of collection.\n\nPlease note that the packaging you send in must be disposed of due to hygiene regulations.\n\nIf you have any further questions, please use the message function. \n\nYours sincerely,\nDentsply Sirona Repair Center',
  KV: 'Dear customer,\n\nwe have received your device for repair. Enclosed you will find the cost estimate.\n\nPlease approve the cost estimate.\n\nTo do so, simply click on the button below. We are available to answer any questions you may have. You are welcome to use the chat function.\n\nYours sincerely,\nDentsply Sirona Repair Center',
  RR: 'Dear customer,\n\nWe have completed the repair of your appliance and would like to send you our work report.\n\nIf you have any further questions, please feel free to send us a message.\n\nYours sincerely,\nDentsply Sirona Repair Center',
  DR: 'Dear customer,\n\nWe have received your device for repair. Enclosed you will find the diagnostic report.\n\nYours sincerely,\nDentsply Sirona Repair Center',
  repairApproved: 'The repair was approved.',
  repairRejectedAndReturnDevice:
    'The repair has been rejected. Please return the device.',
  repairRejectedAndScrapDevice:
    'The repair has been rejected. Please scrap the appliance.',
  setupPasswordText: 'Please set a password to view the Dentsply Sirona products that have already been registered for your dental office or to register additional devices. You can also send your service requests to the Dentsply Sirona Product Service or view previous requests at any time.',
  setupPassword: 'Setup password'
}

export default EN
